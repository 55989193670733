import React from 'react';
import PropTypes from 'prop-types';

import breakpoints from '../../helper/breakpoints';
import isBrowser from '../../helper/is-browser';

import splitScreenStyles from '../split-screen/split-screen.module.scss';

class SplitScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fixedStyles: {},
      isDesktop: isBrowser ? window.innerWidth > breakpoints.xmedium : undefined,
    };

    if (isBrowser) {
      this.addEvents();
    }
  }

  addEvents() {
    window.addEventListener('resize', () => {
      this.setState({
        isDesktop: window.innerWidth > breakpoints.xmedium,
      });

      if (this.state.isDesktop) {
        this.setFixedComponent();
      } else {
        this.setState({
          // remove fixed styling
          fixedStyles: {},
        });
      }
    });
  }

  setFixedComponent() {
    this.setState({
      fixedStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        minHeight: '100%',
      },
    });
  }

  componentDidMount() {
    if (this.state.isDesktop) {
      this.setFixedComponent();
    }
  }

  render() {
    const { node, index } = this.props;
    const steps = [
      `data-start`, // start value (y=0)
      `data-150p`, // value after 100%, 200%, ... scrolled STARTPOINT (except first)
      `data-250p`, // if you scroll further than the element (applies only for 1st and 2nd)
      `data-350p`,
      `data-450p`,
    ];

    const fadeOutAttributes = {}; // TextWrapper
    const fadeInAttributes = {}; // Images
    const fadeInOutTextAttributes = {}; // Text

    fadeOutAttributes[steps[index]] = `transform:translate(0,${(index + 1) * 100}%);`;
    fadeOutAttributes[steps[index + 1]] = 'transform:translate(0,0%);';

    fadeInOutTextAttributes[steps[index]] = `transform:translate(0,${(index + 1) * 100}%);`;
    fadeInOutTextAttributes[steps[index + 1]] = 'transform:translate(0,0%);';
    fadeInOutTextAttributes[steps[index + 2]] = 'transform:translate(0,-200%);';

    fadeInAttributes[steps[index]] = `transform:translate(0, ${(index + 1) * -100}%);`;
    fadeInAttributes[steps[index + 1]] = 'transform:translate(0, 0%);  opacity: 1;';
    fadeInAttributes[steps[index + 2]] = 'opacity: 0;';

    return (
      <div className={splitScreenStyles.splitScreenContainer} style={this.state.fixedStyles}>
        <div className={splitScreenStyles.container}>
          <div
            className={splitScreenStyles.imageContainer}
            data-index={this.props.index}
            style={{
              backgroundColor: this.state.isDesktop
                ? 'transparent'
                : this.props.node.imageBackgroundColor,
            }}
            {...fadeInAttributes}
          >
            <img
              src={node.image.file.url}
              alt={node.imageAltText}
              className={splitScreenStyles.image}
            />
          </div>
          <div className={splitScreenStyles.contentContainer} {...fadeOutAttributes}>
            <div {...fadeInOutTextAttributes}>
              <div
                className={splitScreenStyles.title}
                style={{
                  color: node.titleColor,
                }}
              >
                {node.title}
              </div>

              <h2
                style={{
                  color: node.headlineColor,
                }}
                className={splitScreenStyles.headline}
              >
                {node.headline}
              </h2>
              {!this.state.isDesktop && (
                <div
                  style={{
                    backgroundColor: this.props.node.imageBackgroundColor,
                  }}
                  className={splitScreenStyles.mobileImageWrapper}
                >
                  <img
                    src={node.image.file.url}
                    alt={node.imageAltText}
                    className={`${splitScreenStyles.imageBetweenText} ${
                      this.props.index === 0 ? splitScreenStyles.first : ''
                    }`}
                  />
                </div>
              )}
              <div style={{ color: node.textColor }} className={splitScreenStyles.text}>
                {node.text.text}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SplitScreen.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
  }).isRequired,
};

export default SplitScreen;
