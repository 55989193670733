/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';

import Layout from '../components/layout';

import Hero from '../components/hero';
import SplitScreen from '../components/split-screen/';
import BlockquoteList from '../components/blockquote-list/';
import Benefits from '../components/benefits';
import Features from '../components/features';
import HighlightPanel from '../components/highlight-panel/';
import SignUpPanel from '../components/sign-up-panel/';
import Footer from '../components/footer';

import isBrowser from '../helper/is-browser';
import breakpoints from '../helper/breakpoints';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.isSafari = false;
    this.fixScroll = false;
    this.fixScrollLocation = undefined;
    this.skrollr = undefined;
    this.skrollrWrapper = undefined;
    this.lastSkrollrElements = undefined;

    if (isBrowser) {
      this.isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    }

    this.state = {
      fixedStyles: {},
      skrollrIsMounted: false,
      skrollrWrapperData: {},
      scrollIndicatorStyles: {
        marginBottom: '20px',
        padding: '0',
        width: '14px',
        height: '14px',
        borderRadius: '50%',
      },
      isDesktop: isBrowser ? window.innerWidth > breakpoints.xmedium : undefined,
    };
  }

  getScreenSize() {
    this.setState({
      isDesktop: window.innerWidth > breakpoints.xmedium,
    });
  }

  addEvents() {
    if (isBrowser) {
      window.addEventListener('resize', () => {
        this.getScreenSize();

        if (this.state.skrollrIsMounted) {
          this.setState({
            skrollrWrapperData: {
              dataStart: `${this.skrollrWrapper.scrollHeight}px`,
              dataEnd: `${(this.skrollrWrapper.scrollHeight - window.innerHeight) * -1}px`,
            },
          });
        }

        // check if skrollr is defined. If not, we can can safely return without doing anything
        if (this.state.isDesktop && !this.state.skrollrIsMounted) {
          this.initSkrollr();
        } else if (!this.state.isDesktop && this.state.skrollrIsMounted) {
          this.destroySkrollr();
        }
      });
    }
  }

  initSkrollr() {
    /** With the usual import statement at the top of the file I encountered an error:
     * 'WebpackError: ReferenceError: window is not defined'.
     * This is because webpack has no window object (of course), but Skrollr depends on it.
     * I could not solve it so I use the require statement in the constructor and a
     * check if we are in the browser or if it is a webpack build.
     * It's not nice and maybe a TODO
     */
    const Skrollr = isBrowser ? require('skrollr') : 'undefined'; // eslint-disable-line global-require

    if (!this.state.skrollrIsMounted) {
      // init only once
      this.setFixedComponent();

      this.skrollr = Skrollr.init({
        smoothScrolling: true,
      });

      this.setState({
        skrollrIsMounted: true,
      });
    }
  }

  destroySkrollr() {
    if (this.state.skrollrIsMounted) {
      this.skrollr.destroy();

      // Restore all transform values to 0
      Array.from(document.querySelectorAll('.skrollable')).forEach(element => {
        const singleSkrollItem = element; // stupid es-lint rule
        singleSkrollItem.style.transform = 'translate(0, 0)';
      });

      this.setState({
        skrollrIsMounted: false,
        fixedStyles: {},
      });
    }
  }

  setFixedComponent() {
    this.setState({
      fixedStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        minHeight: '100%',
        overflow: this.isSafari ? 'scroll' : 'none', // stupid safri needs overflow:scroll
      },
    });
  }

  componentDidUpdate() {
    // Very unfortunate, but necessary
    // Skrollr has to initialize after the state is set and therefore we have to put the init in here. It has to wait for the height calculation
    if (this.state.isDesktop && !this.state.skrollrIsMounted) {
      this.initSkrollr();
    }

    if (this.state.skrollrIsMounted) {
      this.skrollr.refresh(); // refresh if the state changes (it will calculate the height of skrollr-wrapper new!)
    }
  }

  componentDidMount() {
    this.addEvents();
    this.skrollrWrapper = document.querySelector('#skrollr-wrapper');

    // Calculate the last section (skrollr-wrapper) height and animate only the full height of it.
    // If we do it with percentage, skrollr will calculate the wrong body height
    this.setState({
      skrollrWrapperData: {
        dataStart: `${this.skrollrWrapper.scrollHeight}px`,
        dataEnd: `${(this.skrollrWrapper.scrollHeight - window.innerHeight) * -1}px`,
      },
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query Homepage {
            allContentfulHomepage {
              edges {
                node {
                  navigationBackgroundColor
                  seo {
                    seoTitle
                    seoKeywords {
                      seoKeywords
                    }
                    seoDescription {
                      seoDescription
                    }
                  }
                  hero {
                    id
                    backgroundColor
                    teaser {
                      teaser
                    }
                    teaserColor
                    headline
                    headlineTextColor
                    heroImage {
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                    heroImageAltText
                    isHomepageHero
                    heroButton {
                      text
                      textColor
                      backgroundColor
                      url
                    }
                  }
                  benefits {
                    backgroundColor
                    leadText
                    textColor
                    headline
                    headlineColor
                    cards {
                      id
                      imageAltText
                      backgroundColor
                      title
                      headline
                      headlineColor
                      textColor
                      description {
                        description
                      }
                      image {
                        file {
                          url
                          fileName
                          contentType
                        }
                      }
                    }
                  }
                  features {
                    backgroundColor
                    leadText
                    textColor
                    headline
                    headlineColor
                    cards {
                      id
                      imageAltText
                      backgroundColor
                      title
                      headline
                      headlineColor
                      textColor
                      description {
                        description
                      }
                      image {
                        file {
                          url
                          fileName
                          contentType
                        }
                      }
                    }
                  }
                  splitScreenStory {
                    id
                    headline
                    title
                    splitScreens {
                      id
                      title
                      titleColor
                      headline
                      headlineColor
                      image {
                        file {
                          url
                          fileName
                          contentType
                        }
                      }
                      imageAltText
                      imageBackgroundColor
                      textColor
                      text {
                        text
                      }
                    }
                  }
                  highlightPanel {
                    highlightButton {
                      text
                      textColor
                      backgroundColor
                      url
                    }
                    backgroundColor
                    title
                    headline
                    headlineColor
                    isSimpleHeadline
                    textColor
                    text {
                      text
                    }
                    imageMiddleTextTop
                    imageBottom
                    imageAltText
                    images {
                      id
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                  }
                  blockquoteList {
                    id
                    backgroundColor
                    headline
                    headlineColor
                    blockquotes {
                      id
                      backgroundColor
                      textColor
                      company
                      position
                      person
                      quoteText {
                        quoteText
                      }
                      textLeftlogoBoxRight
                      logoBox {
                        id
                        backgroundColor
                        image {
                          file {
                            url
                            fileName
                            contentType
                          }
                        }
                        imageAltText
                      }
                    }
                  }
                  signUpPanel {
                    signUpButton {
                      text
                      textColor
                      backgroundColor
                      url
                    }
                    backgroundColor
                    headline
                    headlineColor
                    panelHeadline
                    panelHeadlineColor
                    textBackgroundColor
                    imageBackgroundColor
                    discounttrialBadge
                    discounttrialBadgeTextColor
                    discounttrialBadgeBackgroundColor
                    imageAltText
                    image {
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                    bulletPointList {
                      id
                      bulletPoints {
                        id
                        text
                        textColor
                        imageAltText
                        image {
                          file {
                            url
                            fileName
                            contentType
                          }
                        }
                      }
                    }
                  }
                  footer {
                    id
                    links {
                      id
                      externalUrl
                      slug
                      displayName
                    }
                    logo {
                      id
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ allContentfulHomepage: { edges } }) => {
          const {
            navigationBackgroundColor,
            seo,
            hero,
            benefits,
            features,
            blockquoteList,
            splitScreenStory,
            highlightPanel,
            signUpPanel,
            footer,
          } = head(edges).node;
          const splitScreens = splitScreenStory.splitScreens.map((component, index) => (
            <SplitScreen node={component} index={index} key={component.id} /> // we need the index for the animation
          ));
          return (
            <Layout
              seoTitle={seo.seoTitle}
              seoDescription={seo.seoDescription.seoDescription}
              seoKeywords={seo.seoDescription.seoDescription}
              navigationBackgroundColor={navigationBackgroundColor}
            >
              <section id="hero">
                <Hero node={hero} />
              </section>
              <section
                data-start="background-color:rgb(255, 255, 255);"
                data-100p="background-color:rgb(237, 74, 96);"
                data-150p="background-color:rgb(237, 74, 96);"
                data-250p="background-color:rgb(122, 61, 245);"
                data-350p="background-color:rgb(82, 203, 147);"
                data-450p="transform:translate(0%,0%); background-color:rgb(82, 203, 147);"
                data-550p="transform:translate(0%,-100%);"
                style={this.state.fixedStyles}
                id="split-screen"
              >
                {splitScreens}

                {this.state.isDesktop && (
                  <ul
                    data-50p="transform:translate(100px,0%);"
                    data-120p="transform:translate(0px,0%);"
                    style={{
                      position: 'fixed',
                      display: 'flex',
                      flexDirection: 'column',
                      right: '30px',
                      left: 'auto',
                      top: '50%',
                      listStyleType: 'none',
                    }}
                  >
                    <li
                      data-150p="background-color:rgb(237, 74, 96);"
                      data-250p="background-color:rgb(179, 179, 179);"
                      style={this.state.scrollIndicatorStyles}
                    />
                    <li
                      data-150p="background-color:rgb(179, 179, 179);"
                      data-250p="background-color:rgb(122, 61, 245);"
                      data-350p="background-color:rgb(179, 179, 179);"
                      style={this.state.scrollIndicatorStyles}
                    />
                    <li
                      data-150p="background-color:rgb(179, 179, 179);"
                      data-250p="background-color:rgb(179, 179, 179);"
                      data-350p="background-color:rgb(82, 203, 147);"
                      style={this.state.scrollIndicatorStyles}
                    />
                  </ul>
                )}
              </section>
              <section
                data-200p={`transform:translate(0%,${this.state.skrollrWrapperData.dataStart})`}
                data-end={`transform:translate(0%,${this.state.skrollrWrapperData.dataEnd})`}
                style={this.state.fixedStyles}
                id="skrollr-wrapper"
              >
                <Benefits node={benefits} />
                <Features node={features} />
                <HighlightPanel node={highlightPanel} />
                <BlockquoteList node={blockquoteList} />
                <SignUpPanel node={signUpPanel} />
                <Footer node={footer} />
              </section>
            </Layout>
          );
        }}
      />
    );
  }
}

export default IndexPage;
