import React from 'react';
import PropTypes from 'prop-types';
import { logoBoxPropType } from '../../prop-types';

const LogoBox = ({ className, logoBox }) => (
  <img className={className} src={logoBox.image.file.url} alt={logoBox.imageAltText} />
);

LogoBox.propTypes = {
  className: PropTypes.string,
  logoBox: logoBoxPropType,
};

LogoBox.defaultProps = {
  className: '',
  logoBox: '',
};

export default LogoBox;
