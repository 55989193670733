import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import featureStyles from './features.module.scss';
import FeaturesCard from './features-card';
import Section from '../section';
import PanelHeadline from '../panel-headline';
import { ButtonLink } from '../button';
import { cardPropType } from '../../prop-types';

const Feature = ({
  node: {
    cards,
    backgroundColor,
    buttonUrl,
    buttonLabel,
    leadText,
    textColor,
    headline,
    headlineColor,
  },
}) => (
  <Fragment>
    <Section backgroundColor={backgroundColor}>
      <div className={featureStyles.container}>
        <PanelHeadline
          text={leadText}
          headline={headline}
          headlineColor={headlineColor}
          textColor={textColor}
        />
        <div className={featureStyles.cardList}>
          {cards.map(card => (
            <FeaturesCard key={card.id} node={card} />
          ))}
        </div>
        {buttonUrl && buttonLabel && (
          <div className={featureStyles.callToActionContainer}>
            <ButtonLink type="default" href="">
              Learn More
            </ButtonLink>
          </div>
        )}
      </div>
    </Section>
  </Fragment>
);

Feature.propTypes = {
  node: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    leadText: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    headlineColor: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(cardPropType),
  }).isRequired,
};

export default Feature;
