import React from 'react';
import Card from '../../card';
import featureCardStyles from './features-card.module.scss';
import { cardPropType } from '../../../prop-types';

const FeatureCard = ({
  node: { description, image, imageAltText, headline, backgroundColor, headlineColor, textColor },
}) => (
  <Card
    headline={headline}
    description={description.description}
    headingColor={headlineColor}
    textColor={textColor}
    backgroundColor={backgroundColor}
    src={image.file.url}
    imageAltText={imageAltText}
    containerStyle={featureCardStyles.container}
    textblockStyle={featureCardStyles.textblock}
    headlineStyle={featureCardStyles.headline}
    textStyle={featureCardStyles.text}
    imageStyle={featureCardStyles.image}
  />
);

FeatureCard.propTypes = {
  node: cardPropType.isRequired,
};

export default FeatureCard;
