import React from 'react';
import PropTypes from 'prop-types';

const Card = ({
  backgroundColor,
  textColor,
  headingColor,
  containerStyle,
  textblockStyle,
  headlineStyle,
  headline,
  description,
  textStyle,
  imageStyle,
  src,
  imageAltText,
}) => (
  <div className={containerStyle} style={{ backgroundColor, color: textColor }}>
    <div className={textblockStyle}>
      <h2 style={{ color: headingColor }} className={headlineStyle}>
        {headline}
      </h2>
      <p className={textStyle}>{description}</p>
    </div>
    <img className={imageStyle} src={src} alt={imageAltText} />
  </div>
);

Card.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  headingColor: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  containerStyle: PropTypes.string,
  textblockStyle: PropTypes.string,
  headlineStyle: PropTypes.string,
  textStyle: PropTypes.string,
  imageStyle: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
};

Card.defaultProps = {
  containerStyle: '',
  textblockStyle: '',
  headlineStyle: '',
  textStyle: '',
  imageStyle: '',
  headline: '',
  description: '',
};

export default Card;
