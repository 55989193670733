import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Section from '../section';
import PanelHeadline from '../panel-headline';
import LogoBox from '../logo-box';
import { blockquoteListPropType } from '../../prop-types';

import blockquoteListStyles from '../blockquote-list/blockquote-list.module.scss';

const getBlockquotePositionClass = textLeftlogoBoxRight =>
  textLeftlogoBoxRight ? blockquoteListStyles.blockquote : blockquoteListStyles.blockquoteRight;

const getLogoBoxPositionClass = textLeftlogoBoxRight =>
  textLeftlogoBoxRight ? blockquoteListStyles.logoBox : blockquoteListStyles.logoBoxLeft;

const BlockquoteList = ({ node: { backgroundColor, blockquotes, headline, headlineColor } }) => (
  <Section backgroundColor={backgroundColor}>
    <div className={blockquoteListStyles.container}>
      <div className={blockquoteListStyles.headline}>
        <PanelHeadline simple headline={headline} headlineColor={headlineColor} />
      </div>
      <div className={blockquoteListStyles.blockquoteListSection}>
        <div className={blockquoteListStyles.blockquoteListWrapper}>
          {blockquotes &&
            blockquotes.map(blockquote => (
              <Fragment key={blockquote.id}>
                <div className={blockquoteListStyles.blockquoteWrapper}>
                  <div
                    className={getBlockquotePositionClass(blockquote.textLeftlogoBoxRight)}
                    style={{
                      backgroundColor: blockquote.backgroundColor,
                      color: blockquote.textColor,
                    }}
                  >
                    <div className={blockquoteListStyles.content}>
                      <blockquote className={blockquoteListStyles.quote}>
                        {blockquote.quoteText.quoteText}
                      </blockquote>
                      <ul className={blockquoteListStyles.details}>
                        <li>{blockquote.person}</li>
                        <li className={blockquoteListStyles.credentials} key={blockquote.id}>
                          <div>
                            {blockquote.position},&nbsp;
                            {blockquote.company}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className={getLogoBoxPositionClass(blockquote.textLeftlogoBoxRight)}
                    style={{
                      backgroundColor: blockquote.logoBox.backgroundColor,
                    }}
                  >
                    <LogoBox
                      className={blockquoteListStyles.logoBoxImage}
                      logoBox={blockquote.logoBox}
                    />
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
      </div>
    </div>
  </Section>
);

BlockquoteList.propTypes = {
  node: blockquoteListPropType.isRequired,
};

BlockquoteList.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string.isRequired,
    headlineColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    blockquotes: PropTypes.arrayOf(blockquoteListPropType),
  }).isRequired,
};

export default BlockquoteList;
