import React from 'react';
import Card from '../../card';
import benefitCardStyles from './benefits-card.module.scss';
import { cardPropType } from '../../../prop-types';

const BenefitsCard = ({
  node: { description, image, imageAltText, headline, backgroundColor, headlineColor, textColor },
}) => (
  <Card
    headline={headline}
    description={description.description}
    headingColor={headlineColor}
    textColor={textColor}
    backgroundColor={backgroundColor}
    src={image.file.url}
    imageAltText={imageAltText}
    containerStyle={benefitCardStyles.container}
    textblockStyle={benefitCardStyles.textblock}
    headlineStyle={benefitCardStyles.headline}
    textStyle={benefitCardStyles.text}
    imageStyle={benefitCardStyles.image}
  />
);

BenefitsCard.propTypes = {
  node: cardPropType.isRequired,
};

export default BenefitsCard;
