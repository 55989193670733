import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import benefitStyles from './benefits.module.scss';
import Section from '../section';
import BenefitsCard from './benefits-card';
import PanelHeadline from '../panel-headline';
import { cardPropType } from '../../prop-types';

const Benefits = ({
  node: { backgroundColor, headline, headlineColor, leadText, textColor, cards },
}) => (
  <Fragment>
    <Section backgroundColor={backgroundColor}>
      <div className={benefitStyles.container}>
        <PanelHeadline
          text={leadText}
          headline={headline}
          headlineColor={headlineColor}
          textColor={textColor}
        />
        <div className={benefitStyles.cardList}>
          {cards.map(card => (
            <BenefitsCard key={card.id} node={card} />
          ))}
        </div>
      </div>
    </Section>
  </Fragment>
);

Benefits.propTypes = {
  node: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    leadText: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    headlineColor: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(cardPropType),
  }).isRequired,
};

export default Benefits;
